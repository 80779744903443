var current_carrousel_image = 0;
var carrusel_count_image = 3;
var carrousel_slide_seconds = 10000;
var carrousel_animation_seconds = 2000;
var carousel_animation_timer = null;
var brand_scrollAmount = arrival_scrollAmount = 500;
var currentBlog = null;

$(document).on('click', ".carrousel-arrow-left", carrousel_left);
$(document).on('click', ".carrousel-arrow-right", carrousel_right);
$(document).on('click', "#brand-carousel-arrow-left", brand_carrousel_left);
$(document).on('click', "#brand-carousel-arrow-right", brand_carrousel_right);
$(document).on('click', "#arrival-carousel-arrow-left", arrival_carrousel_left);
$(document).on('click', "#arrival-carousel-arrow-right", arrival_carrousel_right);
$(document).on('click', "#blog-read-more", viewCurrentBlog);


window.addEventListener('scroll', function() {
    let position = window.scrollY;
    if(position<=0){
        $('.transparent_animation').addClass('menu-transparent').removeClass('menu-white');
    }else{
        $('.transparent_animation').removeClass('menu-transparent').addClass('menu-white');
    }
});  
document.addEventListener('visibilitychange', function() {
    if(document.hidden) {
        // tab is now inactive
        // temporarily clear timer using clearInterval() / clearTimeout()
        clearInterval(carousel_animation_timer);
        carousel_animation_timer = null;
    }else {
        // tab is active again
        // restart timers
        if(carousel_animation_timer == null){
            carousel_animation_timer = setInterval(carrouselAnimation, carrousel_slide_seconds);
        }
    }
});
$(document).ready(function(){
    getCarrouselImages();
    getPageCategories();
    getAllBrands();
    getNewArrivals();
    getCompanyInfo();
    getCompanyFrontImage();
    getLastBlog();
    GetRandomActivePromotions();
});
function carrouselAnimation(add = true){
  if(add){
    if(current_carrousel_image+1<carrusel_count_image){
        $('#carrousel-img-'+(current_carrousel_image+1)).css('left', '100%');
        $('#carrousel-img-'+current_carrousel_image).animate({left: '-100%'}, carrousel_animation_seconds*1.05);
        $('#carrousel-img-'+(current_carrousel_image+1)).animate({left: '0%'}, carrousel_animation_seconds);
        current_carrousel_image++;
    }else{
        $('#carrousel-img-0').css('left', '100%');
        $('#carrousel-img-'+current_carrousel_image).animate({left: '-100%'}, carrousel_animation_seconds*1.05);
        $('#carrousel-img-0').animate({left: '0%'}, carrousel_animation_seconds);
        current_carrousel_image = 0;
    }
  }else{
    if(current_carrousel_image-1>=0){
        $('#carrousel-img-'+(current_carrousel_image-1)).css('left', '-100%');
        $('#carrousel-img-'+current_carrousel_image).animate({left: '100%'}, carrousel_animation_seconds*1.05);
        $('#carrousel-img-'+(current_carrousel_image-1)).animate({left: '0%'}, carrousel_animation_seconds);
        current_carrousel_image--;
    }else{
        $('#carrousel-img-'+(carrusel_count_image-1)).css('left', '-100%');
        $('#carrousel-img-'+current_carrousel_image).animate({left: '100%'}, carrousel_animation_seconds*1.05);
        $('#carrousel-img-'+(carrusel_count_image-1)).animate({left: '0%'}, carrousel_animation_seconds);
        current_carrousel_image = carrusel_count_image-1;
    }
  }  
}
function getCarrouselImages(){
  GetMethodFunction('/Home/HomeCarrousel/Get', null, showCarrouselImages, null);
}
function showCarrouselImages(result){
    let appendContent = '';
    let counter = 0;
    //get window size
    let windowWidth = $(window).width();
    if(windowWidth<768){
      for(let i=0; i<result.data.length;i++){
        appendContent += '<div class="carousel-img-containter m-0 p-0'+((i==0)?' carousel-first':' carousel-others')+'" id="carrousel-img-'+counter+'">';
            appendContent += '<div class="row">';
                appendContent += '<div class="col-12 carousel-home-img" style="background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(\''+result.data[i].path+'\')"></div>';
            appendContent += '</div>';
        appendContent += '</div>';
        counter++;
      }
    }else{
      for(let i=0; i<result.data.length;i=i+2){
        appendContent += '<div class="carousel-img-containter m-0 p-0'+((i==0)?' carousel-first':' carousel-others')+'" id="carrousel-img-'+counter+'">';
            appendContent += '<div class="row">';
                appendContent += '<div class="col-6 carousel-home-img" style="background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(\''+result.data[i].path+'\')"></div>';
                appendContent += '<div class="col-6 carousel-home-img" style="background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(\''+result.data[i+1].path+'\')"></div>';
            appendContent += '</div>';
        appendContent += '</div>';
        counter++;
      }
    }
    
    $('.carousel-min-container').empty().append(appendContent);
    carousel_animation_timer = setInterval(carrouselAnimation, carrousel_slide_seconds);
    
}
function getPageCategories() {
    let DataSend = {
        pagination: {
            page: 1,
            size: 10,
            total: 0,
            totalPages:0
        }
    }
    PostMethodFunction('/Home/Category/GetPage', DataSend, null, showPageCategories, function(){});
}
function showPageCategories(response){
    let AppendContent = '';
    let counter = 0;
    let imagePath = '';
    let name = '';
    AppendContent +='<div class="collage-img-container">';
      AppendContent +='<div class="row">';
        AppendContent +='<div class="col-lg-1 order-lg-1"></div>';
        //defining category data
        imagePath = counter<response.data.length?response.data[counter].path:'';
        name = counter<response.data.length?response.data[counter].name:'';
        url = counter<response.data.length?response.data[counter].url:'';
        counter++;
        /////////////////////////
        AppendContent +='<div class="col-lg-3 order-lg-2 collage-cat-img d-flex align-items-end" onclick="location.href=\'/Category/'+url+'\'" style="background-image: url(\''+imagePath+'\')">';
          AppendContent +='<div class="text-overlay mt-auto ms-auto me-0">';
            AppendContent +='<h4 class="custom-space">'+name+'</h4>';
          AppendContent +='</div>';
        AppendContent +='</div>';
        //defining category data
        imagePath = counter<response.data.length?response.data[counter].path:'';
        name = counter<response.data.length?response.data[counter].name:'';
        url = counter<response.data.length?response.data[counter].url:'';
        counter++;
        /////////////////////////
        AppendContent +='<div class="col-lg-4 order-lg-3 collage-cat-img d-flex align-items-end" onclick="location.href=\'/Category/'+url+'\'" style="background-image: url(\''+imagePath+'\')">';
          AppendContent +='<div class="text-overlay mt-auto ms-auto me-0">';
            AppendContent +='<h4 class="custom-space">'+name+'</h4>';
          AppendContent +='</div>';
        AppendContent +='</div>';
        //defining category data
        imagePath = counter<response.data.length?response.data[counter].path:'';
        name = counter<response.data.length?response.data[counter].name:'';
        url = counter<response.data.length?response.data[counter].url:'';
        counter++;
        /////////////////////////
        AppendContent +='<div class="col-lg-3 order-lg-4 collage-cat-img d-flex align-items-end" onclick="location.href=\'/Category/'+url+'\'" style="background-image: url(\''+imagePath+'\')">';
          AppendContent +='<div class="text-overlay mt-auto ms-auto me-0">';
            AppendContent +='<h4 class="custom-space">'+name+'</h4>';
          AppendContent +='</div>';
        AppendContent +='</div>';
        AppendContent +='<div class="col-lg-1 order-lg-5"></div>';
      AppendContent +='</div>';
    AppendContent +='</div>';
    AppendContent +='<div class="collage-img-container">';
      AppendContent +='<div class="row">';
        AppendContent +='<div class="col-lg-1 order-lg-1"></div>';
        //defining category data
        imagePath = counter<response.data.length?response.data[counter].path:'';
        name = counter<response.data.length?response.data[counter].name:'';
        url = counter<response.data.length?response.data[counter].url:'';
        counter++;
        /////////////////////////
        AppendContent +='<div class="col order-lg-2 collage-cat-img d-flex align-items-end" onclick="location.href=\'/Category/'+url+'\'" style="background-image: url(\''+imagePath+'\')">';
          AppendContent +='<div class="text-overlay mt-auto ms-auto me-0">';
            AppendContent +='<h4 class="custom-space">'+name+'</h4>';
          AppendContent +='</div>';
        AppendContent +='</div>';
        //defining category data
        imagePath = counter<response.data.length?response.data[counter].path:'';
        name = counter<response.data.length?response.data[counter].name:'';
        url = counter<response.data.length?response.data[counter].url:'';
        counter++;
        /////////////////////////
        AppendContent +='<div class="col-lg-3 order-lg-3 collage-cat-img d-flex align-items-end" onclick="location.href=\'/Category/'+url+'\'" style="background-image: url(\''+imagePath+'\')">';
          AppendContent +='<div class="text-overlay mt-auto ms-auto me-0">';
            AppendContent +='<h4 class="custom-space">'+name+'</h4>';
          AppendContent +='</div>';
        AppendContent +='</div>';
        AppendContent +='<div class="col-lg-1 order-lg-4"></div>';
      AppendContent +='</div>';
    AppendContent +='</div>';
    AppendContent +='<div class="collage-img-container">';
      AppendContent +='<div class="row">';
        AppendContent +='<div class="col-lg-1 order-lg-1"></div>';
        //defining category data
        imagePath = counter<response.data.length?response.data[counter].path:'';
        name = counter<response.data.length?response.data[counter].name:'';
        url = counter<response.data.length?response.data[counter].url:'';
        counter++;
        /////////////////////////
        AppendContent +='<div class="col-lg-3 order-lg-2 collage-cat-img d-flex align-items-end" onclick="location.href=\'/Category/'+url+'\'" style="background-image: url(\''+imagePath+'\')">';
          AppendContent +='<div class="text-overlay mt-auto ms-auto me-0">';
            AppendContent +='<h4 class="custom-space">'+name+'</h4>';
          AppendContent +='</div>';
        AppendContent +='</div>';
        //defining category data
        imagePath = counter<response.data.length?response.data[counter].path:'';
        name = counter<response.data.length?response.data[counter].name:'';
        url = counter<response.data.length?response.data[counter].url:'';
        counter++;
        /////////////////////////
        AppendContent +='<div class="col-lg-3 order-lg-3 collage-cat-img d-flex align-items-end" onclick="location.href=\'/Category/'+url+'\'" style="background-image: url(\''+imagePath+'\')">';
          AppendContent +='<div class="text-overlay mt-auto ms-auto me-0">';
            AppendContent +='<h4 class="custom-space">'+name+'</h4>';
          AppendContent +='</div>';
        AppendContent +='</div>';
        //defining category data
        imagePath = counter<response.data.length?response.data[counter].path:'';
        name = counter<response.data.length?response.data[counter].name:'';
        url = counter<response.data.length?response.data[counter].url:'';
        counter++;
        /////////////////////////
        AppendContent +='<div class="col-lg-4 order-lg-4 collage-cat-img d-flex align-items-end" onclick="location.href=\'/Category/'+url+'\'" style="background-image: url(\''+imagePath+'\')">';
          AppendContent +='<div class="text-overlay mt-auto ms-auto me-0">';
            AppendContent +='<h4 class="custom-space">'+name+'</h4>';
          AppendContent +='</div>';
        AppendContent +='</div>';
        AppendContent +='<div class="col-lg-1 order-lg-5"></div>';
      AppendContent +='</div>';
    AppendContent +='</div>';
    AppendContent +='<div class="collage-img-container">';
      AppendContent +='<div class="row">';
        AppendContent +='<div class="col-lg-1 order-lg-1"></div>';
        //defining category data
        imagePath = counter<response.data.length?response.data[counter].path:'';
        name = counter<response.data.length?response.data[counter].name:'';
        url = counter<response.data.length?response.data[counter].url:'';
        counter++;
        /////////////////////////
        AppendContent +='<div class="col-lg-5 order-lg-2 collage-cat-img d-flex align-items-end" onclick="location.href=\'/Category/'+url+'\'" style="background-image: url(\''+imagePath+'\')">';
          AppendContent +='<div class="text-overlay mt-auto ms-auto me-0">';
            AppendContent +='<h4 class="custom-space">'+name+'</h4>';
          AppendContent +='</div>';
        AppendContent +='</div>';
        //defining category data
        imagePath = counter<response.data.length?response.data[counter].path:'';
        name = counter<response.data.length?response.data[counter].name:'';
        url = counter<response.data.length?response.data[counter].url:'';
        counter++;
        /////////////////////////
        AppendContent +='<div class="col-lg-5 order-lg-3 collage-cat-img d-flex align-items-end" onclick="location.href=\'/Category/'+url+'\'" style="background-image: url(\''+imagePath+'\')">';
          AppendContent +='<div class="text-overlay mt-auto ms-auto me-0">';
            AppendContent +='<h4 class="custom-space">'+name+'</h4>';
          AppendContent +='</div>';
        AppendContent +='</div>';
        AppendContent +='<div class="col-lg-1 order-lg-4"></div>';
      AppendContent +='</div>';
    AppendContent +='</div>';
    $('#categories-container').append(AppendContent);
}
function carrousel_left(){
  clearInterval(carousel_animation_timer);
  carrouselAnimation(false);
}
function carrousel_right(){
  clearInterval(carousel_animation_timer);
  carrouselAnimation(true);
}
function getAllBrands(){
  GetMethodFunction('/Home/Brand/Get', null, showAllBrands, null);
}
function brand_carrousel_left(){
  var container = $('#brand-carousel-list');
  container.animate({ scrollLeft: container.scrollLeft() - brand_scrollAmount }, 500);
}
function brand_carrousel_right(){
  var container = $('#brand-carousel-list');
  container.animate({ scrollLeft: container.scrollLeft() + brand_scrollAmount }, 500);
}
function showAllBrands(result){ 
  //console.log('result => ', result);
  let AppendContent = '';
  //for(let i=0; i<6; i++){
    $.each(result.brands, function(index, brand){
      AppendContent += '<li class="brand-carousel-list-item my-0 p-0" onclick="location.href=\'/Brand/'+brand.url+'\'">';
        AppendContent += '<img class="brand-carousel-img" src="'+brand.path+'" alt="'+brand.name+'">';
        AppendContent += '<p class="brand-carousel-name">'+brand.name+'</p>';
      AppendContent += '</li>';
    });  
  //}
  
  $('#brand-carousel-list').empty().append(AppendContent);
}
function getNewArrivals(){
  let DataSend = {
    'size':15
  }
  PostMethodFunction('/Home/Catalogue/Product/NewArrivals', DataSend,null, showNewArrivals, null);
}
function showNewArrivals(result){
  //console.log('showNewArrivals => ', result);
  let AppendContent = '';
  let brandsString = '';
  //for(let i=0; i<6; i++){
    $.each(result.data, function(index, arrival){
      AppendContent += '<li class="arrival-carousel-list-item mx-1 my-0 p-0" onclick="location.href=\'/Product/'+arrival.url+'\'">';
        if(arrival.front_images != null && arrival.front_images.length > 0){
          //get random position of front images
          let randomPosition = Math.floor(Math.random() * arrival.front_images.length);
          AppendContent += '<div class="arrival-carousel-img" style="background-image: url(\'/'+arrival.front_images[randomPosition].path+'\')">';
          AppendContent += '</div>';
        }
        AppendContent += '<p class="arrival-carousel-name text-start">'+arrival.name+'</p>';
        //brands
        brandsString = '';
        $.each(arrival.brands, function(index, brand){
          brandsString += brand.name + ', ';
        });
        brandsString = brandsString.substring(0, brandsString.length - 2);
        if(brandsString != ''){
          AppendContent += '<p class="arrival-carousel-brand text-start">'+brandsString+'</p>';
        }
        //normal price
        if(arrival.normal_price != null && arrival.normal_price > 0){
          AppendContent += '<p class="arrival-carousel-normal-price text-start">$'+arrival.normal_price.toLocaleString()+' USD</p>';
        }
        //price
        AppendContent += '<p class="arrival-carousel-price text-start">$'+arrival.price.toLocaleString()+' USD</p>';
      AppendContent += '</li>';
    });  
  //}
  
  $('#arrival-carousel-list li').remove();
  $('#arrival-carousel-list').append(AppendContent);
}
function arrival_carrousel_left(){
  var container = $('#arrival-carousel-list');
  container.animate({ scrollLeft: container.scrollLeft() - arrival_scrollAmount }, 500);
}
function arrival_carrousel_right(){
  var container = $('#arrival-carousel-list');
  container.animate({ scrollLeft: container.scrollLeft() + arrival_scrollAmount }, 500);
}
function getCompanyInfo(){
  GetMethodFunction('/Home/Company/Info/GetCurrent', null, showCompanyInfo, null);
}
function showCompanyInfo(result){
  $('#the-store-text').empty().html(result.data.description.replace(/\n/g, "<br>"));
}
function getCompanyFrontImage(){
  GetMethodFunction('/Home/Company/Info/Galery/GetFrontImage', null, showCompanyFrontImage, null);
}
function showCompanyFrontImage(result){
  $('#the-store-back-bg-img').css('background-image', 'url('+result.data.path+')');
}
function getLastBlog(){
  GetMethodFunction('/Home/Blog/GetLast', null, showLastBlog, null);
}
function showLastBlog(result){
  currentBlog = result.data;
  $('#blog-container #blog-title').text(result.data.title);
  $('#blog-container #blog-author').text(result.data.author);
  $('#blog-container #blog-text').html(result.data.brief.replace(/\n/g, "<br>"));
  $('#blog-container #blog-back-bg-img').css('background-image', 'url('+result.data.path+')');
}
function viewCurrentBlog(){
  if(currentBlog==null) return;
  window.location.href = '/Blog/'+currentBlog.url;
}
function GetRandomActivePromotions(){
  GetMethodFunction('/Home/Promotion/GetRandomActivePromotions', null, showRandomActivePromotions, null);
}
function showRandomActivePromotions(response){
  console.log('showRandomActivePromotions => ', response);
  let promotions =  response.data;
  if(promotions.length == 0){
    $('#promotion-container').remove();
    return;
  }
  let AppendContent = '';
  let promotion_img;
  $.each(promotions, function(index, promotion){
    promotion_img = promotion.random_product.length > 0 ? promotion.random_product[0].path : '';
    AppendContent += '<li class="promotion-item d-flex justify-content-between" onclick="location.href=\'/Promotion/'+promotion.url+'\'">';
      AppendContent += '<div class="promotion-item-img" style="background-image: url(\'/'+promotion_img+'\')"></div>';
      AppendContent += '<div class="promotion-item-text-container d-flex flex-column">';
        AppendContent += '<h4 class="promotion-text promotion-item-title">'+promotion.name+'</h4>';
        AppendContent += '<p class="promotion-text promotion-item-text">'+promotion.description+'</p>';
        AppendContent += '<p class="promotion-text promotion-item-discount align-self-end">'+promotion.discount+'%</p>';
        AppendContent += '</div>';
    AppendContent += '</li>';
  });
  $('#promotion-list').empty().append(AppendContent);
}